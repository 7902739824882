//
// Component: Modal
//
// ========================================================================


// Variables
// ========================================================================

@modal-z-index:                                 @tooltip-z-index + 1;

@modal-dialog-padding:                          @global-margin-medium * 2;
@modal-dialog-background:                       @global-background;
@modal-dialog-border-radius:                    @global-border-radius-xlarge;
@modal-header-margin-bottom:                    @global-margin-large;

@modal-footer-margin-top:                       (@global-margin-medium * 2) - @button-group-margin-y;

@modal-caption-color:                           @global-contrast-color;


// Component
// ========================================================================

.hook-modal() {}


// Sub-object: `uk-modal-dialog`
// ========================================================================

.hook-modal-dialog() {
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 10%;
  color: inherit;

  &.uk-modal-dialog-blank {
    border-radius: 0;
    overflow-y: auto;
    top: 0;
    width: 100% !important;
  }

  border-radius: @modal-dialog-border-radius;

  @media all and (max-width: @breakpoint-medium-max) {
    .uk-modal & {
      width: 95%;
    }
  }

  & > .loading {
    .loading(48px);
  }

  // Accordion content in modal
  .uk-accordion-content {
    padding: 0;
  }

  .input-with-button {
    border-collapse: separate;
    display: table;
    position: relative;
    width: 100%;

    & + div > .uk-accordion-content {
      margin-top: @global-margin;
    }

    input {
      display: table-cell;
      position: relative;
      width: 100%;
    }

    .arrow {
      .position-cover();

      &:before {
        .material-icon();
        content: '\e5cf'; // md-expand_more
        color: @global-base-color;
        text-align: center;
        width: 100%;
        line-height: @global-height;
      }
    }

    a.uk-button {
      display: table-cell;
      position: relative;
      width: 1.5625rem; // 25px

      &.uk-active .arrow:before {
        content: '\e5ce'; // md-expand_less
      }
    }

    [dir=ltr] &,
    [dir=rtl] [dir=ltr] & {
      input {
        border-radius: @global-border-radius 0 0 @global-border-radius;
      }

      a.uk-button {
        border-radius: 0 @global-border-radius @global-border-radius 0;
      }
    }

    [dir=rtl] & {
      input {
        border-radius: 0 @global-border-radius @global-border-radius 0;
      }

      a.uk-button {
        border-radius: @global-border-radius 0 0 @global-border-radius;
      }
    }
  }
}


// Modifier: `uk-modal-dialog-lightbox`
// ========================================================================

.hook-modal-dialog-lightbox() {
  overflow: visible !important;
  margin-bottom: 0;

  /* Phone landscape and smaller */
  @media (max-width: @breakpoint-small-max) {
    .uk-close:first-child {
      [dir=ltr] &,
      [dir=rtl] [dir=ltr] & {
        left: auto;
        right: -20px;
        top: -20px;
      }

      [dir=rtl] & {
        right: auto;
        left: -20px;
        top: -20px;
      }
    }
  }
}


// Sub-object: `uk-modal-header`
// ========================================================================

.hook-modal-header() {
  position: relative;

  .uk-close {
    position: absolute;
    bottom: 0;
    top: 0;
    inset-inline-end: 0;
    width: @md-icon-size;
    height: @md-icon-size;
    text-align: center;
    .button-border-radius(@md-icon-size);

    &:after {
      vertical-align: top;
    }

    &:hover {
      background: none;
    }

    & + .uk-modal-title {
      margin-inline-end: @md-icon-size;
    }
  }

  .uk-modal-title {
    line-height: 1.2;
    margin-top: 0;
  }
}


// Sub-object: `uk-modal-footer`
// ========================================================================

.hook-modal-footer() {}


// Sub-object: `uk-modal-caption`
// ========================================================================

.hook-modal-caption() {}


// Sub-object: `uk-modal-spinner`
// ========================================================================

.hook-modal-spinner() {}


// Miscellaneous
// ========================================================================

.hook-modal-misc() {
  .uk-modal-dialog-fullscreen {
    @modal-fs-header-padding-top: @global-margin-xlarge;
    @modal-fs-header-padding-bottom: @global-margin;
    @modal-fs-sticky-header-min-height: 3rem; // @button-height
    @modal-fs-footer-padding-top: @global-margin-large;
    @modal-fs-footer-padding-bottom: @global-margin-xlarge;

    min-height: 100% !important;
    padding-top: @modal-fs-header-padding-top + @form-height + @modal-fs-header-padding-bottom;
    padding-bottom: @modal-fs-footer-padding-top + @form-height + @modal-fs-footer-padding-bottom;

    .uk-modal-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0;
      padding-top: @modal-fs-header-padding-top;
      padding-bottom: @modal-fs-header-padding-bottom;

      .uk-modal-title {
        line-height: @form-height;
        margin: 0;
      }

      .uk-close {
        position: static;
        line-height: @form-height;

        [dir=ltr] &,
        [dir=rtl] [dir=ltr] & {
          float: right;
        }

        [dir=rtl] & {
          float: left;
        }
      }
    }

    .uk-fullscreen-modal-sticky-header {
      position: fixed;
      top: 0;
      width: 100%;
      padding: @page-header-padding-y @page-header-padding-x;
      background-color: inherit;
      z-index: 1; // Some form elements may overlap.

      &.is-sticky {
        box-shadow: @page-header-box-shadow;
        transition: box-shadow .12s ease-out;
      }

      & > div {
        min-height: @modal-fs-sticky-header-min-height;
      }

      & + .uk-modal-content {
        margin-top: @global-margin-large;
      }

      @media all and (min-width: @breakpoint-large) {
        padding: @page-header-padding-y @page-header-padding-x-large;
      }
    }

    .uk-modal-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      padding-top: @modal-fs-footer-padding-top;
      padding-bottom: @modal-fs-footer-padding-bottom;
    }

    &.uk-modal-dialog-fullscreen-narrow-container {
      .uk-modal-content {
        & > .uk-container-center > * {
          @media all and (min-width: @breakpoint-large) {
            max-width: @utility-narrow-container-large-max-width * 1.25;
          }
        }
      }
    }

    &.uk-modal-dialog-sticky-header {
      padding-top: calc(@page-header-padding-y * 2 + @modal-fs-sticky-header-min-height);
    }
  }

  .uk-modal-content {
    #privacy-policy-modal & {
      max-height: 320px;
      overflow-y: auto;

      @media all and (min-height: 740px) {
        max-height: 440px;
      }
    }

    form > div > .form-item:first-child {
      margin-top: 0;
    }

    h3,
    p {
      .break-word();
    }

    [alt] {
      font-size: @global-font-size-xsmall;
    }
  }

  .mtx-api-filter-modal-page {
    body.page-content-background-enabled {
      .page-content-wrapper {
        z-index: auto;
      }
    }
  }

  // Add: `uk-modal-dialog-qr-code`
  .uk-modal-dialog-qr-code {
    @img-size: 120px;

    word-break: break-word;

    .code-image {
      height: @img-size;
    }

    .mtx-button-group {
      .uk-dropdown {
        width: 100%;
      }
    }

    @media all and (min-width: @breakpoint-small) {
      .content {
        display: flex;
      }

      .code {
        min-width: @img-size;

        [dir=ltr] & {
          margin-right: @global-margin-medium * 2;
        }

        [dir=rtl] & {
          margin-left: @global-margin-medium * 2;
        }
      }
    }

    @media (max-width: @breakpoint-mini-max) {
      .code-image {
        text-align: center;
        margin-bottom: @global-margin-large;
      }
    }
  }

  .uk-modal-filters,
  .uk-modal-actions {
    [dir=ltr] & {
      float: right;
      margin-left: @global-margin-large;
    }

    [dir=rtl] & {
      float: left;
      margin-right: @global-margin-large;
    }

    .uk-modal-close + & {
      [dir=ltr] & {
        margin-right: @close-size + @global-margin-large;
      }

      [dir=rtl] & {
        margin-left: @close-size + @global-margin-large;
      }
    }
  }
}
